<template>
  <Main></Main>
  <Loader v-if="showLoading"/>
</template>

<script>
import Main from './views/MainContainer.vue';
import Loader from './components/loader.vue';
import AOS from "aos";
import { mapState } from "vuex";
import store from "./services/store";
import { LOADING_SPINNER_SHOW_MUTATION } from "./services/store";

export default {
  name: 'App',
  title:'Projecto Managers',
  computed: {
    ...mapState({
      showLoading: (state) => state.showLoading,
    }),
  },
  components: {
    Main,
    Loader
},
  async mounted() {
    AOS.init({ once: true });
    store.commit(LOADING_SPINNER_SHOW_MUTATION, true);
    await new Promise((r) => setTimeout(r, 3000));
    store.commit(LOADING_SPINNER_SHOW_MUTATION, false);
  },
}
</script>

<style>
@import url('./styles/root.css');
</style>
