import { createRouter, createWebHistory }
from "vue-router";
import store from "./store";
import { LOADING_SPINNER_SHOW_MUTATION } from "./store.js";

const routes = [
    {
        path: "/",
        name: "Home",
        meta:{
            title:"Projecto Managers",
            metaTags:[
                {
                    name:"description",
                    content:'PMIL provides specialized engineering,projects m anagem ent,consultancy,and business development services through dedicated, qualified and innovative-thinking engineers,projects managers,and industry experts with requisite and proven technical expertise.'
                }
            ]
        },
        component: ()=> import('../views/HomePage.vue'),
    },
    {
        path: "/about",
        name: "About",
        meta:{
            title:"PMIL | Who we are",
            metaTags:[
                {
                    name:"description",
                    content: "Projecto Managers International Limited (PMIL) is a reputable business development and management company commited to delivering quality and value-added projects with every stretch of excellence. Our services spans across Nigeria and Africa. As your trusted and proven business advisor whose services hinges on engineering, project management, construction, and other related services,our passion for success is unparaleled and second to none."
                }
            ]
        },
        component: ()=> import('../views/AboutPage.vue'),
    },
    {
        path: "/services",
        name: "Services",
        meta:{
            title:"PMIL | What we do",
            metaTags:[
                {
                    name:"description",
                    content: ">> Projects design, management, supervision, and execution. >> Construction, Engineering and Installation services. >> Procurement. >> Consultancy services. >> Energy system and management."
                }
            ]
        },
        component: ()=> import('../views/ServicesPage.vue'),
    },
    {
        path: "/strategy",
        name: "Strategy",
        meta:{
            title:"PMIL | Execution Strategy",
            metaTags:[
                {
                    name:"description",
                    content: "Our well-structured projects execution governing team is readily available with a project execution plan that establishes the means to execute, monitor and control projects.This plan is the main communication vehicle that ensures that everyone tasked with a project is fully abreast with the project objectives and the processes for its accomplishment. At PMIL we rely heavily on integrated 3D CAD and other design tools to develop visual representations that lends itself to real-time online mark-up and review. Engineering and costing is backed up with data from recent projects and the industry that is updated continuously to represent the latest in technical thinking and market factors. As part of design,we look at execution and mitigation of risks."
                }
            ]
        },
        component: ()=> import('../views/StrategyPage.vue'),
    },
    {
        path: "/industries",
        name: "Industries",
        meta:{
            title:"PMIL | Service Industry",
            metaTags:[
                {
                    name:"description",
                    content: "PMIL is equipped and focused on value addition through  application and combination of our service;from independent studies and audits, to delivering entire facilities from project conceptualization to delivery. Our expertise is applied to wide range of projects: building construction (residential and commercial), road construction and bridges, power-electricity and renewable energy and industrial work.."
                }
            ]
        },
        component: ()=> import('../views/IndustriesPage.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    async scrollBehavior() {
        
      store.commit(LOADING_SPINNER_SHOW_MUTATION, true);
      await new Promise(r=>setTimeout(r,1000));
        store.commit(LOADING_SPINNER_SHOW_MUTATION, false);
    //   document.getElementById('app').scrollIntoView({behavior:'smooth'});
   }
  
  })

  router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
  });

  
export default router;