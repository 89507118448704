<template>
    <div class="footer-main">
        <div style="text-align:left;display: flex;align-items: flex-start;justify-content: center;flex-direction: column;">
            <div>
                <img style="margin-bottom:10px" class="menu-logo" src="../../assets/logogray.png"/>
                <div>
                    <p class="quick-links zero-margin pc">Projecto Managers</p>
                    <p class="palt" style="font-size:15px">International Limited</p>
                </div>
            </div>
            <p>PMIL provides specialized engineering, projects management, consultancy, and business development services through dedicated, qualified and innovative-thinking engineers, projects managers, and industry experts with requisite and proven technical expertise</p>
        </div>
        <div class="quick-link-margin" style="text-align:left;display: flex;align-items: flex-start;justify-content: center;flex-direction: column;">
            <p class="quick-links pc">Quick Links</p>
            <p class="links">PMIL Services</p>
            <p class="links">Service Industries</p>
            <p class="links">Execution Strategy</p>
            <p class="links">Make Inquiry</p>
        </div>
        <div>
            <p class="quick-links pc">Get In Touch</p>
            <p class="tx-left"><i class="fa fa-map-pin"></i>&nbsp; 4 Deborah Lawson House F6, Abacha Road, New GRA, Port Harcourt, Rivers State <b class="pc">(Nigeria Office)</b></p>
            <p class="tx-left"><i class="fa fa-map-pin"></i>&nbsp; 732 N Welwood Ave, Lindenhurst, NY 11757, USA <b class="pc">(USA Office)</b></p>
            <p class="tx-left"><i class="fa fa-map-pin"></i>&nbsp; 16 Tarnside, Blackpool FY4 4RD, Lancashire County, England <b class="pc">(UK Office)</b></p>
            <p class="links"><i class="fa fa-envelope"></i>&nbsp; <a class="pc" href="mailto:info@projectomanagers.com"><b>info@projectomanagers.com</b></a></p>
            <p style="text-align: left;"><i class="fa fa-phone"></i>&nbsp; <a class="links pc" href="tel:+2347063835225"><b>+2347063835225</b></a>, <a class="links pc" href="tel:+2348090941660"><b>+2348090941660</b></a></p>
            <div>

            <div style="color: #BF3632;display: flex;column-gap: 20px;font-size: 30px;">
                <a class="a-brand" target="_blank" href="https://web.facebook.com/profile.php?id=61555619308838"><i class="fa fa-facebook"></i></a>
                <a class="a-brand" target="_blank" href="https://instagram.com/pmil_services"><i class="fa fa-instagram"></i></a>
                <a class="a-brand" target="_blank" href="https://twitter.com/ProjectoMa46762"><i class="fa fa-twitter"></i></a>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style>
    @import url('../../styles/footer.css');
</style>