<template>
    <div class="top pbc">

        <a class="a-brand"  href="mailto:info@projectomanagers.com"><i class="fas fa-envelope"></i></a>
        <a style="color: white;" target="_blank" href="https://web.facebook.com/profile.php?id=61555619308838"><i class="fa fa-facebook"></i></a>
        <a style="color: white;" class="a-brand" target="_blank" href="https://instagram.com/pmil_services"><i class="fa fa-instagram"></i></a>
        <a style="color: white;" class="a-brand" target="_blank" href="https://twitter.com/ProjectoMa46762"><i class="fa fa-twitter"></i></a>
        <div class="separator"></div>
        <i class="fa fa-phone"></i>
        <span>
            <a class="a-white" href="tel:+2347063835225">+2347063835225</a>,
            <a class="a-white" href="tel:+2348090941660">+2348090941660</a>
        </span>
    </div>
</template>
<script>
export default {
    
}
</script>
<style>
    @import url('../../styles/header.css');
</style>