<template>
    <div class="header-main-top">
        <div class="menu-flex">
            <router-link to='/' >
                <div class="logo-header">
                    <img class="menu-logo" src="../../assets/logored.png" />
                    <div>
                        <p class="logo-title pc zero-margin">Projecto Managers</p>
                        <p class="zero-margin palt">International Limited</p>
                    </div>
                </div>
            </router-link>
            <div :class="show_ham_menu?'menu-container-toggle':'menu-container'"  @click="show_ham_menu = false">
                <div v-if="show_ham_menu" class="menu-facade"></div>
                <div :class="show_ham_menu ? 'menu-ham-content' : 'menu'">
                    <div class="menu-item" @click="navigate('/')">
                        <span>Home</span>
                        <div :class="currentRouteName == home_title ? 'menu-border-selected' : 'menu-border'"></div>
                    </div>
                    <div style="position: relative;">
                        <div style="display:flex;align-items: center;justify-content: center;">
                            <div class="menu-item" @click="show_about_sub_action()">
                                <span>About</span>
                                <div :class="currentRouteName  == about_title ? 'menu-border-selected' : 'menu-border'"></div>
                            </div>
                            &nbsp;&nbsp;
                            <i class="fa fa-chevron-down" style="font-size: 10px;"></i>
                        </div>
                        <div class="about-sub-menu" v-if="show_about_sub || show_ham_menu">
                            <div class="menu-item" @click="navigate('/about')" v-scroll-to="'#who-we-are'">
                                <span>Who we are</span>
                                <div class='menu-border'></div>
                            </div>
                            <div class="menu-item" @click="navigate('/about')" v-scroll-to="'#pmil-team'">
                                <span>PMIL Team</span>
                                <div class='menu-border'></div>
                            </div>
                        </div>
                    </div>
                    <div class="menu-item" @click="navigate('/services')">
                        <span>Services</span>
                        <div :class="currentRouteName  == service_title ? 'menu-border-selected' : 'menu-border'"></div>
                    </div>
                    <div class="menu-item" @click="navigate('/strategy')">
                        <span>Execution Strategy</span>
                        <div :class="currentRouteName  == strategy_title ? 'menu-border-selected' : 'menu-border'"></div>
                    </div>
                    <div class="menu-item" @click="navigate('/industries')">
                        <span>Industries</span>
                        <div :class="currentRouteName  == industry_title ? 'menu-border-selected' : 'menu-border'"></div>
                    </div>
                </div>
            </div>
            <div class="enquiry">
                <button class="enquiry-button">
                    Make Inquiry
                </button>
            </div>
            <div class="menu-ham" v-if="!show_ham_menu">
                <i class="fa fa-bars" @click="show_ham_menu = true"></i>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            show_ham_menu: false,
            show_about_sub:false,
            home_title: '/',
            about_title: '/about',
            service_title: '/services',
            strategy_title: '/strategy',
            industry_title: '/industries'
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.path;
        }
    },
    methods: {
        show_about_sub_action(){
            this.show_about_sub=!this.show_about_sub
        },
        navigate(route) {
            // this.show_about_sub=false
            this.$router.push(route)
        }
    },
    mounted() {
        this.selected_menu = this.$route.path
    }
}
</script>
<style></style>