<template>
    <div>
        <TopComp/>
        <MenuHeader></MenuHeader>
    </div>
</template>
<script>
import MenuHeader from './MenuHeader.vue';
import TopComp from './TopComp.vue';
export default {
    components:{
        TopComp,
        MenuHeader
    }
}
</script>
<style lang="">
    
</style>