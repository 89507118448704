import {createStore} from 'vuex';
export const LOADING_SPINNER_SHOW_MUTATION='[mutations] show loading spinner';
const store=createStore({
    state(){
        return {
            showLoading:false,
        };
    },
    mutations:{ 
        [LOADING_SPINNER_SHOW_MUTATION](state,payload){
            state.showLoading=payload;
        },
    },
})

export default store;