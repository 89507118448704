<template>
    <div>
        <HeaderComp class="clip-header"></HeaderComp>
        <router-view />
        <footer>
            <FooterComp></FooterComp>
            

        </footer>
        <a class="chat" href="https://wa.me/2347063835225?text=Hello, Projectos Manager!" target="_blank">
            <i class="fas fa-headset"></i>
            <span>Chat with us</span>
        </a>
    </div>
</template>
<script>
import HeaderComp from '../components/header/HeaderComp.vue';
import FooterComp from '../components/header/FooterComp.vue'
export default {
    name:'MainContainer',
    title:'Projecto Managers',
    components:{
        HeaderComp,
        FooterComp
    }
}
</script>
<style>
    @import url('../styles/root.css');
</style>